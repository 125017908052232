import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import './Footer.css'

class Footer extends React.Component {

    render() {
        return (
            <footer>
                <Container id="footer">
                    <Row>
                        <Col md="12" lg>
                            <a href="https://GCUusa.com"><img className="footer-logo" alt="GCU" src="/img/logo-rev.png" /></a>
                        </Col>
                        <Col md>
                            <p>5400 Tuscarawas Rd<br/>
                            Beaver PA, 15009</p>
                        </Col>
                        <Col md>
                            <p>Phone:<br/>
                            1.800.722.4428</p>
                        </Col>
                        <Col md>
                            <p>Fax:<br/>
                            1.724.495.3421</p>
                        </Col>
                        <Col md>
                            <p>Email:<br/>
                            <a href="mailto:info@GCUusa.com">info@GCUusa.com</a></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <small className="translucent">&copy; Copyright { new Date().getFullYear() } GCU</small>
                        </Col>
                    </Row>
                </Container>
            </footer>
        )
    }

}

export default Footer
