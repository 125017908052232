import React, { useState } from 'react'
import { MdClose } from 'react-icons/md'
import { Container, Row, Col } from 'reactstrap'
import './AlertBanner.css'

const AlertBanner = () => {

    const [ isClosed, setIsClosed ] = useState( window.config.hideAlertBanner )

    const closeBanner = () => {
        setIsClosed( true )
    }

    return(
        !isClosed ?
            <div id="alert-banner">
                <Container fluid>
                    <Row>
                        <Col>
                            {window.config.alertText}
                        </Col>
                        <Col xs={1}>
                            <MdClose
                                onClick={closeBanner}
                                id="alert-banner-close-button"
                                className="hover-fade"
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        : null
    )

}

export default AlertBanner
