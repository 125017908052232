import React from 'react'
import { Button } from 'reactstrap'
import './PortalButton.css'

class PortalButton extends React.Component {

    constructor( props ) {
        super( props )
        this.props = props
        this.state = {
            thinPopoverOpen: false,
            thickPopoverOpen: false
        }
    }

    open() {
        var win = window.open( window.config.agentUrl, '_blank' )
        win.focus()
    }

    toggleThick = () => {
        this.setState({
            thickPopoverOpen: !this.state.thickPopoverOpen
        })
    }

    toggleThin = () => {
        this.setState({
            thinPopoverOpen: !this.state.thinPopoverOpen
        })
    }

    render() {
        return (
            <div id="button-container">
                {this.props.fixed?
                    <>
                        {/*<Popover placement="bottom" isOpen={this.state.thinPopoverOpen} target="thin-icon" toggle={this.toggleThin}>
                            <PopoverHeader>Agent Portal</PopoverHeader>
                            <PopoverBody>Portal access is limited to producing agent only. This is where you will find copies of your agent commission statements and download copies of your client applications and policy pages.</PopoverBody>
                        </Popover>
                        <MdInfo id="thin-icon" className="info-icon" onMouseEnter={this.toggleThin} onMouseLeave={this.toggleThin} style={{top:"-58px"}} />*/}
                        <Button onClick={this.open} className="green-button thin-button fixed-top-right float-right hover-fade">Agent portal</Button>
                    </>
                    :
                    <>
                        {/*<Popover placement="bottom" isOpen={this.state.thickPopoverOpen} target="thick-icon" toggle={this.toggleThick}>
                            <PopoverHeader>Agent Portal</PopoverHeader>
                            <PopoverBody>Portal access is limited to producing agent only. This is where you will find copies of your agent commission statements and download copies of your client applications and policy pages.</PopoverBody>
                        </Popover>
                        <MdInfo id="thick-icon" className="info-icon" onMouseEnter={this.toggleThick} onMouseLeave={this.toggleThick} />*/}
                        <Button onClick={this.open} className="green-button thick-button float-right hover-fade">Agent portal</Button>
                    </>
                }
            </div>
        )
    }

}

export default PortalButton
