import React from 'react'
import AppButton from '../AppButton'
import { Container, Row, Col } from 'reactstrap'
import './ColumnBox.css'

class ColumnBox extends React.Component {

    constructor(props) {
        super(props)
        this.props = props
        this.state = {}
    }

    render() {
        return (
            <div className="gray-box">
                <Container>
                    <Row>
                        <Col md>
                            <h3>Agent resources</h3>
                            <ul>
                                {window.links && window.links.agentResources.map((item, index) =>
                                    <li key={index}><a key={index} href={item.href} target="_blank" rel="noopener noreferrer">{item.text}</a></li>
                                )}
                            </ul>
                        </Col>

                        <Col md>
                            {/*<h3>Submitting business</h3>*/}
                            <p><AppButton /></p>
                            <ul>
                                {window.links && window.links.submittingBusiness.map((item, index) =>
                                    <li key={index}><a key={index} href={item.href} target="_blank" rel="noopener noreferrer">{item.text}</a></li>
                                )}
                            </ul>

                            {/*
                            <h6>Application packages</h6>
                            <ul>
                                {window.links && window.links.applicationPackages.map((item, index) =>
                                    <li key={index}><a key={index} href={item.href} target="_blank" rel="noopener noreferrer">{item.text}</a></li>
                                )}
                            </ul>
                            */}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

}

export default ColumnBox
