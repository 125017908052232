import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import TopBar from './components/TopBar'
import HeroSlider from './components/HeroSlider'
import HeroTabs from './components/HeroTabs'
import ColumnBox from './components/ColumnBox'
import Footer from './components/Footer/Footer'
import AlertBanner from './components/AlertBanner'
import './css/App.css'

class App extends React.Component {

    constructor() {
        super()
        this.handleScroll = this.handleScroll.bind(this)
        this.state = {
            showTopBar: false,
            showBarPos: 128
        }
    }

    handleScroll() {
        if (window.pageYOffset > this.state.showBarPos) {
            this.setState({
                showTopBar: true
            })
        } else {
            this.setState({
                showTopBar: false
            })
        }
    }

    componentDidMount() {
        window.onscroll = this.handleScroll
    }

    render() {
        return (
            <>
                <AlertBanner />
                {this.state.showTopBar ?
                    <>
                        <img id="logo-top" src="img/logo_curve.png" alt="GCU" />
                        <TopBar />
                    </>
                    : null}
                <HeroSlider />
                <Container fluid="lg">
                    <Row>
                        <Col>
                            <HeroTabs />
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col className='ml-5'>
                            <h4 style={{ color: "#e74154" }}>The Eternal Advantage product is temporarily unavailable, until further notice. For inquires please email <a href="mailto:agentservices@gcuusa.com">agentservices@gcuusa.com</a>.</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ColumnBox />
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </>
        )
    }
}

export default App
