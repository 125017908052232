import React from 'react'
import { Button } from 'reactstrap'
import './AppButton.css'

class AppButton extends React.Component {

    open() {
        var win = window.open(window.config.eAppUrl, '_blank')
        win.focus()
    }

    render() {
        return (
            /*<Button onClick={this.open} size="lg" className="big-red-button hover-fade">E-Application</Button>*/
            <></>
        )
    }

}

export default AppButton
