import React from 'react'
import { Navbar } from 'reactstrap'
import FadeIn from 'react-fade-in'
import PortalButton from '../PortalButton'
import './TopBar.css'

class TopBar extends React.Component {

    render() {
        return (
                <Navbar id="top-bar">
                    <FadeIn>
                        <h5>Eternal Advantage</h5>
                    </FadeIn>
                    <PortalButton fixed />
                </Navbar>
        )
    }

}

export default TopBar
