import React from 'react'
import AppButton from '../AppButton'
import './HeroTabs.css'

class HeroTabs extends React.Component {

    constructor() {
        super()
        this.state = {}
    }

    render() {
        return (
            /*
            <div className="hero-tabs-container">
                <div className="hero-tabs">
                    <center>
                        <p><AppButton /></p>
                        <p className="faded-text">For fastest decision</p>
                    </center>
                </div>
            </div>
            */
            <></>
        )
    }

}

export default HeroTabs
