import React from 'react'
import PortalButton from '../PortalButton'
import './HeroSlider.css'

class HeroSlider extends React.Component {

    constructor() {
        super()
        this.state = {
            showCaption: false,
            showCapWidth: 650
        }
    }

    componentDidMount() {
        window.onresize = () => {
            if ( window.innerWidth < this.state.showCapWidth ) {
                this.setState({
                    showCaption: true
                })
            } else {
                this.setState({
                    showCaption: false
                })
            }
        }
    }

    render() {
        return(
            <>
                <div className="slide-container">
                    <div className="each-slide">
                        <div className="slide">
                            <PortalButton />
                        </div>
                    </div>
                </div>
                { this.state.showCaption || window.innerWidth < this.state.showCapWidth ?
                    <center><h4 className="title-caption">Final Expense Life Insurance</h4></center>
                : null }
            </>
        )
    }

}

export default HeroSlider
